export default function () {
  const ssrDataLayer = useState<any[]>('ssrDataLayer', () => [])

  function dataLayerPush(data: string[] | Record<string, any>) {
    if (import.meta.server) {
      ssrDataLayer.value.push(data)
    } else {
      try {
        if (!window._mtm) {
          window._mtm = []
        }
        window._mtm.push(data)
      } catch {
        // Noop.
      }
    }
  }

  function trackSwitchPage(url: string, title: string) {
    dataLayerPush({
      event: 'switch_page',
      slug: url,
      page_title: title,
    })
  }

  return {
    dataLayerPush,
    trackSwitchPage,
  }
}

declare global {
  interface Window {
    _mtm: any
  }
}
