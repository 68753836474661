import type {
  FooterMenuLinkFragment,
  MainMenuLinkTreeFirstFragment,
  SiteConfigFragment,
} from '#graphql-operations'

export interface InitData {
  mainMenuLinks: MainMenuLinkTreeFirstFragment[]
  footerMenuLinks: FooterMenuLinkFragment[]
  translations: Record<string, string | [string, string]>
  siteConfig: SiteConfigFragment
}

export default async function (): Promise<Ref<InitData>> {
  const currentLanguage = useCurrentLanguage()
  const config = useRuntimeConfig()
  // Read the host from .env NUXT_HOST to support multisite setup.
  const host = config.requestHost
  const data = useState<InitData>('initData')

  // Let's try to fetch the data from the cache first.
  const event = useRequestEvent()
  const { value, addToCache } = await useDataCache<InitData>(
    'initData_' + currentLanguage.value,
    event,
  )
  if (value) {
    data.value = value
    return data
  }

  // If the data is not in the cache, we fetch it from the payload / useState().
  if (data.value) {
    return data
  }

  // Fetch the data from the server.
  data.value = await useGraphqlQuery({
    name: 'initData',
    fetchOptions: {
      query: {
        language: currentLanguage.value,
        __server: 'true',
      },
      headers: {
        host,
        'x-forwarded-host': host,
      },
    },
  }).then((v) => {
    const initData = {
      mainMenuLinks: v.data.menu?.links || [],
      footerMenuLinks: v.data.menuFooter?.links || [],
      siteConfig: v.data.siteConfig || {},
      translations: Object.entries(
        (v.data.translations || {}) as Record<
          string,
          string | { singular?: string; plural?: string }
        >,
      ).reduce<Record<string, string | [string, string]>>(
        (acc, [fullKey, value]) => {
          const keyWithDots = fullKey.replace('__', '.')
          if (typeof value === 'string') {
            acc[keyWithDots] = value
          } else if (
            typeof value === 'object' &&
            value.plural &&
            value.singular
          ) {
            acc[keyWithDots] = [value.singular, value.plural]
          }
          return acc
        },
        {},
      ),
    }

    // The cache tags are coming from the onServerResponse() function in the graphqlMiddleware.
    if (import.meta.server) {
      addToCache(initData, v.__cacheability?.tagsNuxt, 60)
    }
    return initData
  })

  return data
}
