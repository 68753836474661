import type {
  VisitorStateEmployerFragment,
  VisitorStatePensionFundFragment,
} from '#build/graphql-operations'
import { notNullish } from '@vueuse/core'
import type { LinkItem } from '~/types/global'

type UseVisitorState = {
  setEmployerId: (id?: string) => Promise<void>
  employerUrl: ComputedRef<string | undefined>
  employerLinks: ComputedRef<LinkItem[]>
  employerId: ComputedRef<string | undefined>
  hasSelectedEmployer: ComputedRef<boolean>
  pensionFund: ComputedRef<VisitorStatePensionFundFragment | null>
  employer: ComputedRef<VisitorStateEmployerFragment | null>
  load: () => Promise<void>
}

export default function (): UseVisitorState {
  const selectedEmployerId = useCookie('selectedEmployerId', {
    default: () => '',
    decode: (v) => v,
  })
  const employerData = useState<VisitorStateEmployerFragment | null>(
    'employer_data',
  )

  const load = (): Promise<void> => {
    if (!selectedEmployerId.value) {
      employerData.value = null
      return Promise.resolve()
    }

    if (import.meta.server) {
      return Promise.resolve()
    }

    return useGraphqlQuery('visitorState', {
      id: selectedEmployerId.value,
    }).then((data) => {
      if (
        data &&
        data.data &&
        data.data.entityById?.__typename === 'NodeEmployer'
      ) {
        employerData.value = data.data.entityById || null
      } else {
        employerData.value = null
        selectedEmployerId.value = ''
      }
    })
  }

  const setEmployerId = (id?: string): Promise<void> => {
    selectedEmployerId.value = id || ''
    return load()
  }

  const pensionFund = computed<VisitorStatePensionFundFragment | null>(
    () => employerData.value?.pensionFund || null,
  )

  const employerLinks = computed<LinkItem[]>(() => {
    const url = pensionFund.value?.url?.path
    if (!url) {
      return []
    }
    return (pensionFund.value.paragraphs || [])
      .map((v) => {
        if (
          v &&
          'navSectionTeaser' in v &&
          v.navSectionTeaser?.title &&
          v.navSectionTeaser.anchor
        ) {
          return {
            title: v.navSectionTeaser.title,
            to: url + '#' + v.navSectionTeaser.anchor,
          }
        }

        return null
      })
      .filter(notNullish)
  })

  const employerUrl = computed(() => employerData.value?.url?.path)

  const hasSelectedEmployer = computed<boolean>(
    () => !!(selectedEmployerId.value && employerData.value),
  )

  const employer = computed(() => {
    return employerData.value
  })

  const employerId = computed(() => selectedEmployerId.value)

  return {
    setEmployerId,
    employerUrl,
    employerLinks,
    hasSelectedEmployer,
    pensionFund,
    employer,
    load,
    employerId,
  }
}
